import React from 'react';
import { Table } from 'reactstrap';

export default class AssignmentTable extends React.Component {

    render () {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(this.props.assignments).map( (key,index) => {
                    return <tr key={index}>
                        <td>{key}</td>
                        <td>{this.props.assignments[key]}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        );
    }

} 