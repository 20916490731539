import React from 'react';
import AceEditor from 'react-ace';
import SALMode from './SALMode';
import 'brace/theme/xcode';

export default class SALEditor extends React.Component {

    componentDidMount() {
		const salMode = new SALMode();
		this.refs.aceEditor.editor.getSession().setMode(salMode);
	}

    render() {
        return (
            <AceEditor 
                ref="aceEditor"
                name="sal-editor"
                mode="text"
                fontSize={14}
                style={{width: '100%'}}
                theme="xcode"
                value={this.props.value} 
                onChange={this.props.onChange} 
            />
        );
    }
}
