import React from 'react';
import { Input, InputGroup, InputGroupButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class Combo extends React.Component {
   
    constructor(props){
        super();
        this.state = { 
            dropdownOpen: false,
            selected: ''
        };
    }
    
    toggleDropdown = (ev) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
          });
    }
   
    select = (val) => {
        this.setState({
            selected: val
          });
        this.props.onChange(val);
    }

    render() {
        let hideDropdown = this.props.disabled || !this.props.values || this.props.values.length === 0;
        return (
            <InputGroup>
                <Input id="inputField" placeholder={this.props.placeholder} 
                    disabled={this.props.disabled} onChange={(ev)=>this.select(ev.target.value)} value={this.state.selected}/>
                <InputGroupButtonDropdown 
                        addonType="append" 
                        isOpen={this.state.dropdownOpen} 
                        toggle={this.toggleDropdown}>
                    <DropdownToggle disabled={hideDropdown} split></DropdownToggle>
                    {this.props.values &&
                        <DropdownMenu>
                            {this.props.values && this.props.values.map( (val,idx) => {
                                    return <DropdownItem key={'key_val_'+idx} onClick={()=>this.select(val)}>{val}</DropdownItem>    
                                })}
                        </DropdownMenu>
                    }
                </InputGroupButtonDropdown>
            </InputGroup>
        );
    }

}
