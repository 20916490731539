import React, { Fragment } from 'react';
import ErrorModal from './ErrorModal';
import SALInput from './SALInput';
import SALOutput from './SALOutput';
import NLInput from './NLInput';
import IRPanel from './IRPanel';
import { Button, Row, Col, Navbar, NavbarBrand, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { hideApiError, generateModel, generateNL, showIR, hideIR } from '../actions/Actions';

class Home extends React.Component {

  toggleIR = () => {
      if (this.props.irIsHidden){
          this.props.onShowIR();
      }
      else {
          this.props.onHideIR();
      }
  }

  render () {
    return (
      <Fragment>
        <Navbar color="light">
            <NavbarBrand><h3>Arsenal</h3></NavbarBrand>
        </Navbar>

        <main style={{backgroundColor: 'lightblue'}}>
            <Container fluid={true} className="pt-sm-4">
                <ErrorModal 
                        title="Server Error"
                        error={this.props.apiError} 
                        show={this.props.apiError != null}
                        onHide={this.props.onHideApiError}
                />
                <Row className="mb-sm-4">
                    <Col md={12}>
                        <NLInput/>
                    </Col>
                </Row>
                <Row className="mb-sm-4">
                    <Col md={12}>
                        <Button 
                            className="mr-sm-2"
                            disabled={!this.props.nl || this.props.irIsLoading} 
                            onClick={()=>this.props.onGenerateModel(this.props.nl)}>
                                {this.props.irIsLoading?'Loading...':'Generate model'}
                        </Button>
                        <Button 
                            disabled={!this.props.sal.salModel || this.props.nlIsLoading} 
                            onClick={()=>this.props.onGenerateNL(this.props.sal.salModel)}>
                                {this.props.nlIsLoading?'Loading...':'Generate English'}
                        </Button>
                        <Button 
                            disabled={!this.props.ir}
                            className="float-right"
                            onClick={this.toggleIR}>
                                {this.props.irIsHidden?'Show IR':'Hide IR'}
                        </Button>
                    </Col>
                </Row>
                {!this.props.irIsHidden && 
                <Row>
                    <Col md={12}>
                        <IRPanel ir={this.props.ir}/>
                    </Col>
                </Row>
                }
                <Row>
                    <Col md={12}>
                        <SALInput sal={this.props.sal}></SALInput>
                    </Col>
                </Row>
                <Row>
                <Col md={12}>
                        <SALOutput></SALOutput>
                    </Col>
                </Row>
            </Container>
        </main>

        <footer className="footer text-center">
          <div className="container">
            <span className="text-muted">&copy; SRI International 2018</span>
          </div>
        </footer>
      </Fragment>
    );
  }

}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch,ownProps) {
  return {
      onHideApiError: () => { dispatch(hideApiError()) },
      onGenerateModel: (text) => { dispatch(generateModel(text)) },
      onGenerateNL: (sal) => { dispatch(generateNL(sal)) },
      onShowIR: () => { dispatch(showIR()) },
      onHideIR: () => { dispatch(hideIR()) },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home);
