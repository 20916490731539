import React from 'react';
import { Button } from 'reactstrap';
import AssignmentTable from './AssignmentTable';

export default class SALCounterExample extends React.Component {

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
    }

    previous = () => {
        const nextIndex = this.state.activeIndex === 0 ? this.props.steps.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    next = () => {
        const nextIndex = this.state.activeIndex === this.props.steps.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    render() {
        const { activeIndex } = this.state;
        let step = this.props.steps[activeIndex];
        let lastIndex = this.props.steps.length-1;
        let svgText = step.graph;
        let svgDiv = <div dangerouslySetInnerHTML={{__html: svgText}}></div>;
        let assignments = step.assignments;

        return <div>
            <Button className="mr-sm-2" disabled={activeIndex===0} onClick={this.previous}>Previous</Button>
            {'Step ' + (activeIndex+1) + '/' + (lastIndex+1)}
            <Button className="ml-sm-2" disabled={activeIndex===lastIndex} onClick={this.next}>Next</Button>
            {svgDiv}
            <AssignmentTable assignments={assignments}/>
        </div>;
    }

} 