import React from 'react';
import { Button, Row, Col, ListGroup, ListGroupItem,
    TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip
} from 'reactstrap';
import { connect } from 'react-redux';
import { fetchWfcResult, fetchDlcResult, fetchBmcResult, fetchSmcResult, 
    setSALModel, showSAL, hideSAL } from '../actions/Actions';
import classnames from 'classnames';
import Combo from './Combo';
import SALEditor from './SALEditor';

class SALInput extends React.Component {
   
    constructor(props){
        super();
        this.state = { 
            theorem: '', 
            module: '', 
            activeTab: '1'
        };
    }
    
    handleChangeSal = (newText) => {
        this.props.onChangeSal(newText);
    }

    handleChangeTheorem = (th) => {
        this.setState({
            theorem: th
        });
    }

    handleChangeModule = (mod) => {
        this.setState({
            module: mod
        });
    }

    selectTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    buildProblemList = () => {
        let problems = this.props.sal.problems;
        if (problems && problems.length > 0){
            return <ListGroup>
                {problems.map( (problem) => {
                    return <ListGroupItem>{problem}</ListGroupItem>
                })}</ListGroup>;
        } 
        else {
            return <ListGroup><ListGroupItem>No problems found</ListGroupItem></ListGroup>;
        }               
    }

    toggleSAL = () => {
        if (this.props.sal.isHidden){
            this.props.showSAL();
        }
        else {
            this.props.hideSAL();
        }
    }
    
    render() {
        //let showProblemsTab = this.state.sal.problems && this.state.sal.problems.length > 0;
        let showProblemsTab = true;

        /*
            <Button 
                disabled={!this.props.sal.salModel}
                className="mb-sm-2"
                onClick={this.toggleSAL}>{this.props.sal.isHidden?'Show SAL':'Hide SAL'}</Button>
            <br/>
        */

        return (
            <div>
                {!this.props.sal.isHidden &&
                <div className="mb-sm-4">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.selectTab('1'); }}>
                            SAL Model
                            </NavLink>
                        </NavItem>
                        <NavItem disabled={!showProblemsTab}>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.selectTab('2'); }}>
                            Problems
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <SALEditor 
                                value={this.props.sal.salModel} 
                                onChange={this.handleChangeSal} 
                            />
                        </TabPane>
                        <TabPane tabId="2" disabled={!showProblemsTab}>
                            {this.buildProblemList()}
                        </TabPane>
                    </TabContent>
                </div>
                }
                <div>
                    <Button className="mb-sm-2" disabled={!this.props.sal.salModel} onClick={()=>this.props.onWfc(this.props.sal.salModel)}>Check well-formedness</Button>

                    <Row className="mb-sm-2">
                        <Col sm="3">
                            <Combo 
                                disabled={!this.props.sal} 
                                placeholder='Module name' 
                                values={this.props.sal.modules}
                                onChange={this.handleChangeModule}
                            />
                        </Col>
                        <Button 
                            id="dlc-button"
                            disabled={!this.state.module || !this.props.sal.finite} 
                            onClick={()=>this.props.onDlc(this.props.sal.salModel, this.state.module)}>Check for deadlocks</Button>
                        {!this.props.sal.finite &&
                            <UncontrolledTooltip target="dlc-button">
                                Model is not finite; cannot check for deadlocks.
                            </UncontrolledTooltip>
                        }
                    </Row>

                    <Row className="mb-sm-4">
                        <Col sm="3">
                            <Combo 
                                disabled={!this.props.sal} 
                                placeholder='Assertion name' 
                                values={this.props.sal.assertions}
                                onChange={this.handleChangeTheorem}
                            />
                        </Col>
                        <Button disabled={!this.state.theorem} className="mr-sm-2" onClick={()=>this.props.onBmc(this.props.sal.salModel,this.state.theorem)}>Bounded model check</Button>
                        <Button 
                            id="smc-button"
                            disabled={!this.state.theorem || !this.props.sal.finite} 
                            onClick={()=>this.props.onSmc(this.props.sal.salModel,this.state.theorem)}>Symbolic model check</Button>
                        {!this.props.sal.finite &&
                            <UncontrolledTooltip target="smc-button">
                                Model is not finite; cannot run symbolic model checker (try the bounded model checker instead).
                            </UncontrolledTooltip>
                        }
                    </Row>


                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return state;
}
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        onChangeSal: (sal) => { dispatch(setSALModel(sal)) },
        onWfc: (sal) => { dispatch(fetchWfcResult(sal)) },
        onDlc: (sal,mod) => { dispatch(fetchDlcResult(sal,mod)) },
        onBmc: (sal,theorem) => { dispatch(fetchBmcResult(sal,theorem)) },
        onSmc: (sal,theorem) => { dispatch(fetchSmcResult(sal,theorem)) },
        showSAL: () => { dispatch(showSAL()) },
        hideSAL: () => { dispatch(hideSAL()) }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SALInput);
