import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';

export default class RelationTable extends React.Component {

    render () {
        return (
            <Card>
                <CardHeader>Relations</CardHeader>
                <CardBody>
                    {this.props.ir &&
                    <Table>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>arg1</th>
                                <th>arg2</th>
                                <th>type</th>
                                <th>sentence id</th>
                                <th>sentence</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.ir.sentences.flatMap( (sent,sent_idx) => {
                                return sent.relations.map( (rel, rel_idx) => {
                                    return <tr key={"tr" + sent_idx + "_rel_" + rel_idx}>
                                            <td>{rel.id} </td>
                                            <td>{rel.arg1}</td>
                                            <td>{rel.arg2}</td>
                                            <td>{rel.relationType}</td>
                                            <td>{sent.id}</td>
                                            <td>{sent.nl}</td>
                                        </tr>
                                    })
                                })
                            }
                        </tbody>
                    </Table>}
                </CardBody>
            </Card>
     
        );
    }

}