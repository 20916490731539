export const NOOP = 'NOOP';

export const SHOW_API_ERROR = 'SHOW_API_ERROR';
export const HIDE_API_ERROR = 'HIDE_API_ERROR';

export const REQUEST_SAL_MODEL = 'REQUEST_SAL_MODEL';
export const REQUEST_WFC_RESULT = 'REQUEST_WFC_RESULT';
export const REQUEST_DLC_RESULT = 'REQUEST_DLC_RESULT';
export const REQUEST_MC_RESULT = 'REQUEST_MC_RESULT';
export const REQUEST_IR_RESULT = 'REQUEST_IR_RESULT';
export const REQUEST_NL = 'REQUEST_NL';

export const RECEIVE_SAL_MODEL = 'RECEIVE_SAL_MODEL';
export const RECEIVE_WFC_RESULT = 'RECEIVE_WFC_RESULT';
export const RECEIVE_DLC_RESULT = 'RECEIVE_DLC_RESULT';
export const RECEIVE_MC_RESULT = 'RECEIVE_MC_RESULT';
export const RECEIVE_IR_RESULT = 'RECEIVE_IR_RESULT';

export const HIDE_WFC_MODAL = 'HIDE_WFC_MODAL';
export const HIDE_DLC_MODAL = 'HIDE_DLC_MODAL';

export const SET_SAL_MODEL = 'SET_SAL_MODEL';
export const SET_NL = 'SET_NL';

export const HIDE_IR = 'HIDE_IR';
export const SHOW_IR = 'SHOW_IR';

export const HIDE_SAL = 'HIDE_SAL';
export const SHOW_SAL = 'SHOW_SAL';
