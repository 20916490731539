import * as Actions from './ActionTypes';

//import Viz from 'viz.js';
//import { Module, render } from 'viz.js/full.render.js';
//let viz = new Viz({ Module, render });

export function showApiError(error){
    return { type: Actions.SHOW_API_ERROR, error }
}

export function hideApiError(){
    return { type: Actions.HIDE_API_ERROR }
}

export function hideIR() {
    return { type: Actions.HIDE_IR }
}

export function showIR() {
    return { type: Actions.SHOW_IR }
}

export function hideSAL() {
    return { type: Actions.HIDE_SAL }
}

export function showSAL() {
    return { type: Actions.SHOW_SAL }
}

export function requestSALModel(ir) {
    return { type: Actions.REQUEST_SAL_MODEL, ir }
}
  
export function receiveSALModel(model) {
    return { type: Actions.RECEIVE_SAL_MODEL, model }
}

export function setSALModel(text) {
    return { type: Actions.SET_SAL_MODEL, text }
}

export function setNL(text) {
    return { type: Actions.SET_NL, text}
}

export function requestWfcResult() {
    return { type: Actions.REQUEST_WFC_RESULT }
}
  
export function receiveWfcResult(result) {
    return { type: Actions.RECEIVE_WFC_RESULT, result }
}

export function requestDlcResult() {
    return { type: Actions.REQUEST_DLC_RESULT }
}
  
export function receiveDlcResult(result) {
    return { type: Actions.RECEIVE_DLC_RESULT, result }
}

export function requestMcResult() {
    return { type: Actions.REQUEST_MC_RESULT }
}
  
export function receiveMcResult(result) {
    return { type: Actions.RECEIVE_MC_RESULT, result }
}

export function hideWfcModal() {
    return { type: Actions.HIDE_WFC_MODAL }
}

export function hideDlcModal() {
    return { type: Actions.HIDE_DLC_MODAL }
}

export function requestIRResult(text) {
    return { type: Actions.REQUEST_IR_RESULT, text }
}
  
export function receiveIRResult(result) {
    return { type: Actions.RECEIVE_IR_RESULT, result }
}

export function requestNL(text) {
    return { type: Actions.REQUEST_NL, text }
}
  
// Async "pseudo-actions" actions below
// These trigger two actions: A "request" action (immediately) and a "receive" action (some time later),
// or alternatively, an api error action if something went wrong.

export function generateModel(text){
    return (dispatch,getState) => {
        dispatch(requestIRResult(text));
        fetch('/nlp2ir/generateir', { method: "POST", //headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
            redirect: "follow", body: JSON.stringify({
                    Username: "TestUser",
                    GroupName: "TestGroup",
                    msg: text2json(text) 
                })
            })
            .then( (response) => {
                if (response.ok) return response.json();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (json) => {
                dispatch(receiveIRResult(json));
                dispatch(fetchSALModel(json));
            })
            .catch( (error) => dispatch(showApiError(error)) );
    }

}

function ir2sentences(ir){
    let nlList = ir.sentences.map( (s) => s.nl );
    return nlList.join('\n');
}

export function generateNL(model){
    return (dispatch,getState) => {
        dispatch(requestNL(model));
        fetch('/ir2sal/rest/ir', { method: "POST", headers: { "Content-Type": "text/plain", "Accept": "application/json" }, 
            redirect: "follow", body: model
            })
            .then( (response) => {
                if (response.ok) return response.json();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (result) => {
                dispatch(receiveIRResult(result)) 
                let sentences = ir2sentences(result);
                dispatch(setNL(sentences));
            })
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

export function fetchSALModel(model){
    return (dispatch,getState) => {
        dispatch(requestSALModel(model));
        fetch('/ir2sal/rest/salmodel', { method: "POST", headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
            redirect: "follow", body: JSON.stringify(model)
            })
            .then( (response) => {
                if (response.ok) return response.json();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (result) => dispatch(receiveSALModel(result)) )
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

export function fetchWfcResult(model){
    return (dispatch,getState) => {
        dispatch(requestWfcResult());
        fetch('/ir2sal/rest/wfc', { method: "POST", headers: { "Content-Type": "text/plain", "Accept": "text/plain" }, 
        redirect: "follow", body: model })
        .then( (response) => {
            if (response.ok) return response.text();
            else return response.text().then(text => {throw Error(text)});
        })
        .then( (result) => dispatch(receiveWfcResult(result)) )
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

export function fetchDlcResult(model, mod){
    return (dispatch,getState) => {
        dispatch(requestDlcResult());
        fetch('/ir2sal/rest/dlc', { method: "POST", headers: { "Content-Type": "application/json", "Accept": "text/plain" }, 
            redirect: "follow", body: JSON.stringify({
                    salModel: model,
                    moduleName: mod
                })
            })
            .then( (response) => {
                if (response.ok) return response.text();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (result) => dispatch(receiveDlcResult(result)) )
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

/*
// Converts dot to img using the viz.js library
function processDot(result){
    console.log('processing dot in',result);
    if (result.counterExample){
        //let div = document.createElement('div');  // fake parent used below

        return Promise.all(result.counterExample.map( step => {
            console.log('rendering dot');
            let dot = step.graph;
            //let img = viz.renderImageElement(dot);  // returns a Promise containing an img element
            //let img = viz.renderString(dot);
            let img = viz.renderString(dot);
            console.log('rendered img',img);
            return img;
        }));

        console.log('Promise.all returned');
        let newResult = {...result};

        return all.then( (vizs) => {   // crash here
            console.log('inside then');
            vizs.forEach( (svg,index) => { 
                console.log('inserting IMG');
                // div.appendChild(svg);
                // let htmlStr = div.innerHTML;
                // div.removeChild(svg);
                let htmlStr = svg.outerHTML;
                newResult.counterExample[index].graph = null;  // save some memory
                newResult.counterExample[index].img = htmlStr;
            });
            return newResult;
        });
    }
    else {
        return result;
    }
}
*/

export function fetchBmcResult(model, theorem){
    return (dispatch,getState) => {
        dispatch(requestMcResult());
        fetch('/ir2sal/rest/bmc', { method: "POST", headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
            redirect: "follow", body: JSON.stringify({
                    salModel: model,
                    theoremName: theorem
                })
            })
            .then( (response) => {
                if (response.ok) return response.json();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (result) => dispatch(receiveMcResult(result)) )
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

export function fetchSmcResult(model, theorem){
    return (dispatch,getState) => {
        dispatch(requestMcResult());
        fetch('/ir2sal/rest/smc', { method: "POST", headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
            redirect: "follow", body: JSON.stringify({
                    salModel: model,
                    theoremName: theorem
                })
            })
            .then( (response) => {
                if (response.ok) return response.json();
                else return response.text().then(text => {throw Error(text)});
            })
            .then( (result) => dispatch(receiveMcResult(result)) )
            .catch( (error) => dispatch(showApiError(error)) );
    }
}

// Creates proper JSON input for nlp2ir
function text2json(text){
    let lines = text.split('\n');
    return lines.map( (line,index) => { 
        return {
            id: 'S' + index,
            sentence: line
        };
    });
}
