import React from 'react';
import SALResultModal from './SALResultModal';
import SALMCResult from './SALMCResult';
import { connect } from 'react-redux';
import { hideDlcModal, hideWfcModal } from '../actions/Actions';

class SALOutput extends React.Component {

    render () {
        return (
            <div>
                <SALResultModal 
                    title="Well-formedness checker result"
                    text={this.props.wfcResult} 
                    show={this.props.showWfcModal}
                    onHide={this.props.onHideWfcModal}
                />
                <SALResultModal 
                    title="Deadlock checker result"
                    text={this.props.dlcResult} 
                    show={this.props.showDlcModal}
                    onHide={this.props.onHideDlcModal}
                />
                {this.props.mcResult && 
                    <SALMCResult result={this.props.mcResult}/>
                }
            </div>
        );
    }

}

function mapStateToProps(state) {
    return state;
}
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        onHideDlcModal: () => { dispatch(hideDlcModal()) },
        onHideWfcModal: () => { dispatch(hideWfcModal()) }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SALOutput);
