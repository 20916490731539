//import { combineReducers } from 'redux';
import * as Actions from '../actions/ActionTypes';

export const initialState = {
  nl: '',
  sal: {
    salModel: '',
    modules: [],
    assertions: [],
    finite: true,
    isHidden: false
  },
  dlcResult: null,
  wfcResult: null,
  mcResult: null,
  ir: null,
  irIsHidden: true,
  irIsLoading: false,
  nlIsLoading: false,
  apiError: null,
  showWfcModal: false,
  showDlcModal: false
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_API_ERROR: return {...state, apiError: action.error };
    case Actions.HIDE_API_ERROR: return {...state, apiError: null };
    case Actions.REQUEST_SAL_MODEL: return {...state, sal: { ...state.sal, salModel:'' } };
    case Actions.REQUEST_DLC_RESULT: return {...state, dlcResult: null };
    case Actions.REQUEST_WFC_RESULT: return {...state, wfcResult: null };
    case Actions.REQUEST_MC_RESULT: return {...state, mcResult: null };
    case Actions.REQUEST_IR_RESULT: return {...state, ir: null, irIsLoading: true };
    case Actions.REQUEST_NL: return {...state, nl: '' };
    case Actions.RECEIVE_SAL_MODEL: return {
      ...state, 
      sal: action.model
    };
    case Actions.RECEIVE_DLC_RESULT: return {
      ...state, 
      dlcResult: action.result,
      showDlcModal: true
    };
    case Actions.RECEIVE_WFC_RESULT: return {
      ...state, 
      wfcResult: action.result,
      showWfcModal: true
    };
    case Actions.HIDE_DLC_MODAL: return {
      ...state, 
      showDlcModal: false
    };
    case Actions.HIDE_WFC_MODAL: return {
      ...state, 
      showWfcModal: false
    };
    case Actions.RECEIVE_MC_RESULT: return {...state, mcResult: action.result };
    case Actions.RECEIVE_IR_RESULT: return {...state, ir: action.result, irIsLoading: false };
    case Actions.SET_SAL_MODEL: return {
      ...state, 
      sal: {...state.sal, salModel: action.text, finite: true }
    };    
    case Actions.SET_NL: return {...state, nl: action.text }
    case Actions.HIDE_IR: return {...state, irIsHidden: true };
    case Actions.SHOW_IR: return {...state, irIsHidden: false };
    case Actions.HIDE_SAL: return {...state, sal: {...state.sal, isHidden: true }};
    case Actions.SHOW_SAL: return {...state, sal: {...state.sal, isHidden: false }};
    default: return state;
  }
}

export default rootReducer;