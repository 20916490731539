import * as ace from "brace";
import "brace/mode/java";

export default class SALMode extends ace.acequire('ace/mode/java').Mode {
    constructor() {
        super();
        this.HighlightRules = SALHighlightRules;
        //this.$id = 'arsenal/mode/sal';
    }
}

// Turn a space-separated string of words into a regex of alternatives
const clean = (str) =>
    str
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    .replace(/ /g, '|')

class SALHighlightRules extends ace.acequire("ace/mode/text_highlight_rules").TextHighlightRules { 
    constructor() {
        super();
		this.$rules = {
			"start": [{
				token: "comment",
				regex: "%.*$"
            }, {
                token: 'keyword',
                regex: clean('BEGIN DATATYPE DEFINITION ELSE ELSIF ' + 
                    'ENDIF END EXISTS FORALL GLOBAL IF INITIALIZATION INPUT ' +
                    'LAMBDA LET LOCAL OBLIGATION ' +
                    'OF OUTPUT RENAME THEN TO TRANSITION WITH')
            }, {
                token: 'storage.type',
                regex: clean('ARRAY BOOLEAN CLAIM CONTEXT INTEGER LEMMA MODULE NATURAL NZINTEGER NZREAL REAL THEOREM TYPE')
            }, {
                token: 'constant.language',
                regex: clean('TRUE FALSE')
            }, {
                token: 'keyword.operator',
                regex: clean('|- .. /= =< >= [] || => <=> --> + - / * AND IN NOT OR XOR G F')
            }, {
				token: "constant.numeric",
				regex: /0|([1-9][0-9]*)/
            }, {
				token: "variable",
                regex: /[a-zA-Z][a-zA-Z0-9_]*/
            }]
		};
    }
}

