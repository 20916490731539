import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';

export default class EntityTable extends React.Component {

    constructor(props){
        super();
        this.state = { hidden: false };
    }

    /*
    toggle = () => {
        this.setState({
            hidden: !this.state.hidden
        });
    }

    toggleButtonText = () => {
        if (this.state.hidden){
            return "Show";
        }
        else {
            return "Hide";
        }
    }
                    <Button className="float-right" onClick={this.toggle}>{this.toggleButtonText}</Button>
    */

    render () {
        return (
            <Card>
                <CardHeader>Entities</CardHeader>
                {!this.state.hidden && 
                    <CardBody>
                        {this.props.ir &&
                        <Table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>text</th>
                                    <th>type</th>
                                    <th>sentence id</th>
                                    <th>sentence</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.ir.sentences.flatMap( (sent,sent_idx) => {
                                    return sent.entities.map( (ent, ent_idx) => {
                                        return <tr key={"tr" + sent_idx + "_ent_" + ent_idx}>
                                                <td>{ent.id} </td>
                                                <td>{ent.text}</td>
                                                <td>{ent.entityType}</td>
                                                <td>{sent.id}</td>
                                                <td>{sent.nl}</td>
                                            </tr>
                                        })
                                    })
                                }
                            </tbody>
                        </Table>}
                    </CardBody>
                }
            </Card>
     
        );
    }

}