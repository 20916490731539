import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';

export default class AssertionInfo extends React.Component {
   
    render() {
        let sentRefs = this.props.assertion.sentenceRefs;
        let hasSentenceRefs = sentRefs && sentRefs.length > 0; 
        return (
            <Form className="mb-sm-4 ml-sm-4">
                <FormGroup row className="mb-sm-1">
                    <Label><span sm={2} style={{ fontWeight: 'bold' }}>Assertion name</span>{'  ' + this.props.assertion.name}</Label>
                </FormGroup>
                <FormGroup row className="mb-sm-1">
                    <Label><span sm={2} style={{ fontWeight: 'bold' }}>Assertion expression</span>{'  ' + this.props.assertion.sal}</Label>
                </FormGroup>
                <FormGroup row className="mb-sm-1">
                    <Label><span sm={2} style={{ fontWeight: 'bold' }}>From sentences</span>{'  ' + 
                    ((hasSentenceRefs && sentRefs) || '(none)')}</Label>
                </FormGroup>
                <FormGroup row className="mb-sm-1">
                    <Label><span sm={2} style={{ fontWeight: 'bold' }}>Status</span>{'  ' + 
                    this.props.status}</Label>
                </FormGroup>
            </Form>
        );
    }

}
