import React from 'react';
import EntityTable from './EntityTable';
import RelationTable from './RelationTable';

export default class IRPanel extends React.Component {
   
    render() {
        return (
            <div className="mb-sm-4">
                <div>
                    <div className="mb-sm-4">
                        <EntityTable ir={this.props.ir}/>
                    </div>
                    <div className="mb-sm-4">
                        <RelationTable ir={this.props.ir}/>
                    </div>
                </div>
            </div>
        );
    }

}
