import React from 'react';
import { Card, CardHeader, CardBody, Label } from 'reactstrap';
import SALCounterExample from './SALCounterExample';
import AssertionInfo from './AssertionInfo';

export default class SALMCResult extends React.Component {

    render () {
        return (
            <div className="mb-sm-4">
                <Card>
                    <CardHeader>Model-checking Result</CardHeader>
                    <CardBody>
                        <AssertionInfo 
                            assertion={this.props.result.assertion}
                            status={this.props.result.status}
                        />
                        {this.props.result.counterExample && 
                            <SALCounterExample steps={this.props.result.counterExample}/>
                        }
                        {this.props.result.errorMessage &&
                            <Label>{this.props.result.errorMessage}</Label>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

} 